import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../../backend'
import { collection, doc, updateDoc, query, where, onSnapshot, getDoc, documentId, getDocs } from 'firebase/firestore'

export const saveClient = createAsyncThunk('clients/saveClient', async ({ id, data }) => {
	return updateDoc(doc(firestore, 'clients', id), data)
})

export const clientsSlice = createSlice({
	name: 'clients',
	initialState: {
		value: [],
		status: 'idle',
		isClientsLoaded: false,
		error: null,
		selectedClient: {},
		isClientDetailModalOpen: false,
	},
	reducers: {
		setClients: (state, action) => {
			state.value = action.payload
		},
		mergeClients: (state, action) => {
			state.value = [...state.value.filter(({ id }) => !action.payload.find(obj => obj.id === id)), ...action.payload]
		},
		updateClientInClients: (state, action) => {
			const idx = state.value.findIndex(({ id }) => id === action.payload.id)
			state.value[idx] = action.payload
		},
		setClientStatus: (state, action) => {
			state.status = action.payload
		},
		setSelectedClient: (state, action) => {
			state.selectedClient = action.payload
		},
		setIsClientDetailModalOpen: (state, action) => {
			state.isClientDetailModalOpen = action.payload
		},
		setIsClientsLoaded: (state, action) => {
			state.isClientsLoaded = action.payload
		},
	},
	extraReducers: {
		[saveClient.pending]: (state, action) => {
			state.status = 'loading'
		},
		[saveClient.fulfilled]: (state, action) => {
			state.status = 'succeeded'
		},
		[saveClient.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

export const fetchClients = venueId => (dispatch, getState) => {
	return onSnapshot(
		query(collection(firestore, 'clients'), where('etablissement', '==', venueId), where('toLoad', '==', true)),
		querySnapshot => {
			const clients = querySnapshot.docs.map(doc => {
				const { lastReservationDate, ...data } = doc.data()
				return { ...data, id: doc.id }
			})
			dispatch(setIsClientsLoaded(true))
			if (getState().clients.value.length === 0) {
				dispatch(setClients(clients))
			} else {
				dispatch(mergeClients(clients))
			}
		},
		error => {
			console.log(error)
		}
	)
}

export const fetchClientsFromAccountId = async (accountId, venueId) => {
	const req = await getDocs(query(collection(firestore, 'clients'), where('account', '==', accountId), where('etablissement', '==', venueId)))
	return req.docs.map(doc => {
		const { lastReservationDate, ...rest } = doc.data()
		return { ...rest, id: doc.id }
	})
}

export const loadClientFromId = async clientId => {
	const docSnap = await getDoc(doc(firestore, 'clients', clientId))
	const { lastReservationDate, ...rest } = docSnap.data()
	return { ...rest, id: docSnap.id }
}

export const fetchClientsByBatches = (clientsIds, venueId) => {
	const arrClientsIds = clientsIds.reduce((res, _, index) => {
		if (index > 0 && index % 10 === 0) {
			const arr = clientsIds.slice(10 * (index / 10 - 1), index)
			const promise = getDocs(query(collection(firestore, 'clients'), where(documentId(), 'in', arr), where('etablissement', '==', venueId)))
			return [...res, promise]
		} else if (index === clientsIds.length - 1) {
			const arr = clientsIds.slice(clientsIds.length - 1 - (index % 10))
			const promise = getDocs(query(collection(firestore, 'clients'), where(documentId(), 'in', arr), where('etablissement', '==', venueId)))
			return [...res, promise]
		}
		return res
	}, [])
	// const residual = clientsIds.slice(-clientsIds.length % 10)
	// console.log('b',residual.length)
	// residual.length > 0 && arrClientsIds.push(getDocs(query(collection(firestore, 'clients'), where(documentId(), 'in', residual))))

	return Promise.all(arrClientsIds)
}

export const updateClient = (id, data) => {
	return updateDoc(doc(firestore, 'clients', id), data)
}

export const getNewClientId = () => {
	return doc(collection(firestore, 'clients')).id
}

export const getClients = state => state.clients.value
export const getClientStatus = state => state.clients.status
export const getSelectedClient = state => state.clients.selectedClient
export const getIsClientDetailModalOpen = state => state.clients.isClientDetailModalOpen
export const getIsClientsLoaded = state => state.clients.isClientsLoaded

export const { updateClientInClients, setClients, setClientStatus, setSelectedClient, setIsClientDetailModalOpen, setIsClientsLoaded, mergeClients } = clientsSlice.actions

export default clientsSlice.reducer
