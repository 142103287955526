import { AiOutlineCheck, AiOutlineClockCircle, AiOutlineStop } from 'react-icons/ai'

const mappingAccountClientType = t => ({
	assignNewAccount: t('assign_to_new_account'),
	assignOldAccount: t('assign_to_existing_account'),
	switchAccount: t('replace_by_existing_account'),
	newAccount: t('replace_by_new_account'),
	noAccount: t('detach_account'),
	editAccount: t('edit_account'),
	editClient: t('edit_contact'),
	switchClient: t('replace_by_existing_contact'),
	newClient: t('replace_by_new_contact'),
})

const accountTypesMapping = t => ({
	old: t('existing_account'),
	new: t('new_account'),
	no: t('no_account'),
})

const accountClientMapping = t => ({
	'no.new': t('new_client_no_account'),
	'old.new': t('new_client_existing_account'),
	'new.new': t('new_client_new_account'),
	'no.old': t('existing_client_no_account'),
	'old.old': t('existing_client_existing_account'),
})

const algoliaMapping = t => ({
	events: t('events'),
	clients: t('clients'),
	accounts: t('accounts'),
	event_dateUnix_facet: t('events_dates'),
	event_followupDateUnix_facet: t('follow_up'),
	files_creation_dates_facet: t('invoices_creation_dates'),
	quotes_creation_dates_facet: t('quotes_creation_dates'),
	creation_dates_facet: t('creation_dates_facet'),
	quotes_amount_facet: t('quotes_amount_facet'),
	files_amount_facet: t('files_amount_facet'),
	payments_amount_facet: t('payments_amount_facet'),
	sourceId_facet: t('sources'),
	statusId_facet: t('statuss'),
	owners_facet: t('owners'),
	quotes_without_facet: t('quotes'),
	files_without_facet: t('invoices'),
	payments_without_facet: t('payments'),
	holds_without_facet: t('pre_auth_payments'),
	rooms_without_facet: t('rooms_blocks'),
	spaces_facet: t('spaces'),
	etablissement: t('venues'),
	clients_rate_facet: t('rating'),
})

const feedMapping = t => [
	{ id: 'statusId', label: t('status') },
	{ id: 'sourceId', label: t('source') },
	{ id: 'pax', label: t('pax') },
	{ id: 'date', label: t('date') },
	{ id: 'heureArrivee', label: t('hour_start') },
	{ id: 'heureFin', label: t('hour_end') },
	{ id: 'espace', label: t('space') },
	{ id: 'occasion', label: t('event_type') },
	{ id: 'remoteVenueAddress', label: t('event_location') },
	{ id: 'followupDateUnix', label: t('follow_up_date') },
	{ id: 'owners', label: t('owners') },
	{ id: 'option', label: t('option') },
	{ id: 'commentaires', label: t('comments') },
	{ id: 'notSendReview', label: t('rating_ask') },
]

const notifTemplate = ({ message, date, client, email, account = '', occasion = '' }, t) => t('notif_email_template_fr', { message, date, client, email, account, occasion })

const notifThunderLinkSMS = (venueId, linkId, signatureSMS = '', t) => t('notif_thunder_link_sms_fr', { venueId, linkId, signatureSMS })
const notifThunderLinkSMSEn = (venueId, linkId, signatureSMS = '', t) => t('notif_thunder_link_sms_en', { venueId, linkId, signatureSMS })
const notifThunderLinkEmail = (venueId, linkId, signatureEmail = '', t) => t('notif_thunder_link_email_fr', { venueId, linkId, signatureEmail })
const notifThunderLinkEmailEn = (venueId, linkId, signatureEmail = '', t) => t('notif_thunder_link_email_en', { venueId, linkId, signatureEmail })

const paymentStatusMapping = t => [
	{ id: 'waiting', label: t('waiting'), color: 'gold', tagColor: 'gold', icon: <AiOutlineClockCircle /> },
	{ id: 'paid', label: t('paid'), color: '#20BF55', tagColor: 'green', icon: <AiOutlineCheck /> },
	{ id: 'canceled', label: t('canceled'), color: 'lightgray', tagColor: 'lightgray', icon: <AiOutlineStop /> },
	{ id: 'waiting', label: t('waiting'), color: 'gold', tagColor: 'gold', isHold: true, icon: <AiOutlineClockCircle /> },
	{ id: 'active', label: 'Reçue', color: '#20BF55', tagColor: 'green', isHold: true, icon: <AiOutlineCheck /> },
	{ id: 'canceled', label: t('canceled'), color: 'lightgray', tagColor: 'lightgray', isHold: true, icon: <AiOutlineStop /> },
]

const invoiceStatus = t => [
	{ id: 'canceled', label: t('canceled'), color: 'lightgray', tagColor: 'lightgray', icon: <AiOutlineStop /> },
	{ id: 'ongoing', label: t('waiting'), color: 'gold', tagColor: 'gold', icon: <AiOutlineClockCircle /> },
	{ id: 'paid', label: t('paid'), color: '#20BF55', tagColor: 'green', icon: <AiOutlineCheck /> },
]

const quoteStatus = t => [
	{ id: 'canceled', label: t('canceled'), color: 'lightgray', tagColor: 'lightgray', icon: <AiOutlineStop /> },
	{ id: 'ongoing', label: t('waiting'), color: 'gold', tagColor: 'gold', icon: <AiOutlineClockCircle /> },
	{ id: 'validated', label: t('validated'), color: '#20BF55', tagColor: 'green', icon: <AiOutlineCheck /> },
]

const paymentsMethods = t => [t('card_on_site'), t('card_remote'), t('voucher'), t('check'), t('cash'), t('online_payments'), t('wire_transfer'), t('restaurant_vouchers')]

const fields = t => [
	{ id: 'publicId+reservations', code: 'publicId', collection: 'reservations', name: t('event_id') },
	{ id: 'status+reservations', code: 'status', collection: 'reservations', name: t('status') },
	{ id: 'owners+reservations', code: 'owners', collection: 'reservations', name: t('owner') },
	{ id: 'espace+reservations', code: 'espace', collection: 'reservations', name: t('space') },
	{ id: 'occasion+reservations', code: 'occasion', collection: 'reservations', name: t('event_type') },
	{ id: 'date+reservations', code: 'date', collection: 'reservations', name: t('date') },
	{ id: 'hours_event+reservations', code: 'heureArrivee+heureFin+event', collection: 'reservations', name: t('hours_event') },
	{ id: 'hours_global+reservations', code: 'heureArrivee+heureFin+global', collection: 'reservations', name: t('hours_global') },
	{ id: 'pax+reservations', code: 'pax', collection: 'reservations', name: t('pax_number') },
	{ id: 'pax_total+reservations', code: 'pax+total', collection: 'reservations', name: t('pax_total') },
	{ id: 'remoteVenueAddress+reservations', code: 'remoteVenueAddress', collection: 'reservations', name: t('external_venue') },
	{ id: 'commentaires+reservations', code: 'commentaires', collection: 'reservations', name: t('comments'), type: 'textarea' },
	{ id: 'comments_group+reservations', code: 'comments_group', collection: 'reservations', name: t('comments_group'), type: 'textarea' },
	{ id: 'name+reservations', code: 'name', collection: 'reservations', name: t('event_name') },
	{ id: 'contact+clients', code: 'prenom+nom', collection: 'clients', name: t('contact') },
	{ id: 'phone_email+clients', code: 'telephone+email', collection: 'clients', name: t('personal_infos') },
	{ id: 'telephone+clients', code: 'telephone', collection: 'clients', name: t('phone_number') },
	{ id: 'telephoneFixe+clients', code: 'telephoneFixe', collection: 'clients', name: t('land_phone') },
	{ id: 'email+clients', code: 'email', collection: 'clients', name: t('email') },
	{ id: 'name+accounts', code: 'name', collection: 'accounts', name: t('account_name') },
	{ id: 'nom+etablissements', code: 'nom', collection: 'etablissements', name: t('venue_name') },
	{ id: 'adresse+etablissements', code: 'adresse', collection: 'etablissements', name: t('venue_address') },
	{ id: 'base+reservations', code: 'base_bloc', collection: 'reservations', name: t('services'), type: 'base_bloc' },
	{ id: 'rooms+reservations', code: 'rooms_bloc', collection: 'reservations', name: t('rooms'), type: 'rooms_bloc' },
	{ id: 'vat+accounting', code: 'vat', collection: 'accounting', name: t('vat') },
	{ id: 'total_excluding_vat+accounting', code: 'total_excluding_vat', collection: 'accounting', name: t('total_excluding_vat'), type: 'currency' },
	{ id: 'total_including_vat+accounting', code: 'total_including_vat', collection: 'accounting', name: t('total_including_vat'), type: 'currency' },
	{ id: 'commentairesPaiement+reservations', code: 'commentairesPaiement', collection: 'accounting', name: t('comments_accounting'), type: 'textarea' },
	{ id: 'deposits+accounting', code: 'deposits', collection: 'accounting', name: t('deposits'), type: 'deposits' },
	{ id: 'invoicing_total+accounting', code: 'invoicing_total', collection: 'accounting', name: t('total'), type: 'invoicing_total' },
	{ id: 'rest+accounting', code: 'rest', collection: 'accounting', name: t('rest'), type: 'rest' },
]

const mappingTypes = t => [
	{ label: t('unique_select_list'), value: 'select' },
	{ label: t('multi_select_list'), value: 'multi-select' },
	{ label: t('short_text'), value: 'input' },
	{ label: t('long_text'), value: 'textarea' },
]
const mappingCollection = t => [
	{ label: t('event'), value: 'reservations' },
	{ label: t('account'), value: 'accounts' },
	{ label: t('client'), value: 'clients' },
	// { label: t('rooms'), value: 'rooms' },
	// { label: t('accounting'), value: 'accounting' },
]

const mappingAccounting = t => ({
	devis: t('quote'),
	acompte: t('deposit'),
	solde: t('balance'),
	'acompte-avoir': t('credit_note_deposit_menu'),
	'solde-avoir': t('credit_note_balance_menu'),
	facture: t('invoice'),
	'facture-avoir': t('credit_note_menu'),
})

const langMapping = () => [
	{ value: 'fr', label: '🇫🇷', long_label: `Français 🇫🇷` },
	{ value: 'en', label: '🇺🇸', long_label: `English 🇺🇸` },
	// { value: 'es', label: '🇪🇸', long_label: `Español 🇪🇸` },
	// { value: 'it', label: '🇮🇹', long_label: `Italiano 🇮🇹` },
	{ value: 'nl', label: '🇳🇱', long_label: `Nederlands 🇳🇱` },
]

const systemLangMapping = () => [
	{ value: 'fr', label: '🇫🇷', long_label: `Français 🇫🇷` },
	{ value: 'en', label: '🇺🇸', long_label: `English 🇺🇸` },
]

const defaultCountries = [
	{ value: 'FR', label: 'France', flag: '🇫🇷' },
	{ value: 'GB', label: 'United Kingdom', flag: '🇬🇧' },
	{ value: 'CH', label: 'Suisse', flag: '🇨🇭' },
	{ value: 'IT', label: 'Italia', flag: '🇮🇹' },
	{ value: 'NL', label: 'Nederland', flag: '🇳🇱' },
	{ value: 'MA', label: 'Maroc', flag: '🇲🇦' },
]

const defaultAccountAddressFields = {
	fields: [
		{ id: 'adresse1', label: 'address', type: 'input', isRequiredForInvoice: true },
		{ id: 'adresse2', label: 'address_more', type: 'input' },
		{ id: 'codePostal', label: 'postal_code', type: 'input', isRequiredForInvoice: true },
		{ id: 'ville', label: 'city', type: 'input', isRequiredForInvoice: true },
		{ id: 'pays', label: 'country', type: 'input', isRequiredForInvoice: true },
	],
	layout: [{ value: ['adresse1'] }, { value: ['adresse2'] }, { value: ['codePostal', 'ville', 'pays'] }],
}

const defaultAccountAccountingAddressFields = {
	fields: [
		{ id: 'adresse1Facturation', label: 'address_accounting', type: 'input', isRequiredForInvoice: true },
		{ id: 'adresse2Facturation', label: 'address_accounting', type: 'input' },
		{ id: 'codePostalFacturation', label: 'postal_code', type: 'input', isRequiredForInvoice: true },
		{ id: 'villeFacturation', label: 'city', type: 'input', isRequiredForInvoice: true },
		{ id: 'paysFacturation', label: 'country', type: 'input', isRequiredForInvoice: true },
	],
	layout: [{ value: ['adresse1Facturation'] }, { value: ['adresse2Facturation'] }, { value: ['codePostalFacturation', 'villeFacturation', 'paysFacturation'] }],
}

const defaultClientFields = {
	fields: [
		{ id: 'prenom', label: 'firstname', isRequiredInBO: true, isRequiredForInvoice: true },
		{ id: 'nom', label: 'lastname', isRequiredInBO: true, isRequiredForInvoice: true },
		{ id: 'email', label: 'email' },
	],
	layout: [{ value: ['prenom', 'nom'] }, { value: ['email'] }],
}

const defaultClientAddressFields = {
	fields: [
		{ id: 'adresse1', label: 'address', type: 'input', isRequiredForInvoice: true },
		{ id: 'adresse2', label: 'address_more', type: 'input' },
		{ id: 'codePostal', label: 'postal_code', type: 'input', isRequiredForInvoice: true },
		{ id: 'ville', label: 'city', type: 'input', isRequiredForInvoice: true },
		{ id: 'pays', label: 'country', type: 'input', isRequiredForInvoice: true },
	],
	layout: [{ value: ['adresse1'] }, { value: ['adresse2'] }, { value: ['codePostal', 'ville', 'pays'] }],
}

// const ZENCHEF_DASHBOARD_URL = 'http://preapp.zenchef.io'
const ZENCHEF_DASHBOARD_URL = 'https://app.zenchef.com'
// const ZENCHEF_API_URL = 'https://preapi.zenchef.io'
const ZENCHEF_API_URL = 'https://api.zenchef.com'

// const THAIS_PMS_API_URL = 'https://demo.thais-hotel.com/hub/api/partner'

const FRONTAPP_CHAT_ID = '9f6c136466a15123bff92a1f610e7544'

export {
	FRONTAPP_CHAT_ID,
	fields,
	ZENCHEF_DASHBOARD_URL,
	ZENCHEF_API_URL,
	defaultCountries,
	defaultAccountAddressFields,
	defaultAccountAccountingAddressFields,
	defaultClientAddressFields,
	defaultClientFields,
	systemLangMapping,
	langMapping,
	quoteStatus,
	invoiceStatus,
	paymentsMethods,
	paymentStatusMapping,
	mappingAccountClientType,
	accountTypesMapping,
	accountClientMapping,
	algoliaMapping,
	feedMapping,
	notifTemplate,
	notifThunderLinkSMS,
	notifThunderLinkEmail,
	notifThunderLinkEmailEn,
	notifThunderLinkSMSEn,
	mappingCollection,
	mappingTypes,
	mappingAccounting,
}
