import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../../backend'
import { collection, query, where, updateDoc, doc, setDoc, getDocs } from 'firebase/firestore'

export const saveStep = createAsyncThunk('steps/saveStep', async ({ id, data }) => {
	if (id) {
		return updateDoc(doc(firestore, 'steps', id), data)
	} else {
		const stepId = doc(collection(firestore, 'steps')).id
		return setDoc(doc(firestore, 'steps', stepId), data, { merge: true })
	}
})

export const fetchSteps = createAsyncThunk('steps/fetchSteps', async (venueId, { dispatch }) => {
	try {
		const querySnapshot = await getDocs(query(collection(firestore, 'steps'), where('etablissement', '==', venueId)))
		const steps = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
		dispatch(setSteps(steps))
	} catch (error) {
		console.log(error)
	}
})

export const stepsSlice = createSlice({
	name: 'steps',
	initialState: {
		value: [],
		status: 'idle',
		error: null,
	},
	reducers: {
		setSteps: (state, action) => {
			state.value = action.payload
		},
		setStepStatus: (state, action) => {
			state.status = action.payload
		},
	},
	extraReducers: {
		[saveStep.pending]: (state, action) => {
			state.status = 'loading'
		},
		[saveStep.fulfilled]: (state, action) => {
			state.status = 'succeeded'
		},
		[saveStep.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

// export const fetchSteps = venueId => dispatch => {
// 	return onSnapshot(
// 		query(collection(firestore, 'steps'), where('etablissement', '==', venueId)),
// 		querySnapshot => {
// 			const steps = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
// 			dispatch(setIsStepsLoaded(true))
// 			dispatch(setSteps(steps))
// 		},
// 		error => {
// 			console.log(error)
// 		}
// 	)
// }

export const getSteps = state => state.steps.value
export const getStepsStatus = state => state.steps.status

export const { setSteps, setStepStatus } = stepsSlice.actions

export default stepsSlice.reducer
