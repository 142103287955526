import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../../backend'
import { collection, doc, updateDoc, query, where, onSnapshot, getDoc, documentId, getDocs, setDoc } from 'firebase/firestore'

export const saveAccount = createAsyncThunk('accounts/saveAccount', async ({ id, data }) => {
	if (id) {
		return updateDoc(doc(firestore, 'accounts', id), data)
	} else {
		const accountId = doc(collection(firestore, 'accounts')).id
		return setDoc(doc(firestore, 'accounts', accountId), data, { merge: true })
	}
})

export const accountsSlice = createSlice({
	name: 'accounts',
	initialState: {
		value: [],
		status: 'idle',
		isAccountsLoaded: false,
		error: null,
		selectedAccount: {},
		isAccountDetailModalOpen: false,
	},
	reducers: {
		setAccounts: (state, action) => {
			state.value = action.payload
		},
		mergeAccounts: (state, action) => {
			state.value = [...state.value.filter(({ id }) => !action.payload.find(obj => obj.id === id)), ...action.payload]
		},
		updateAccountInAccounts: (state, action) => {
			// action.payload.forEach(obj => {
			// 	const idx = state.value.findIndex(({ id }) => id === obj.id)
			// 	if (idx === -1) {
			// 		state.value.push(obj)
			// 	} else {
			// 		state.value[idx] = action.payload
			// 	}
			// })
			const idx = state.value.findIndex(({ id }) => id === action.payload.id)
			state.value[idx] = action.payload
		},
		setAccountStatus: (state, action) => {
			state.status = action.payload
		},
		setSelectedAccount: (state, action) => {
			state.selectedAccount = action.payload
		},
		setIsAccountDetailModalOpen: (state, action) => {
			state.isAccountDetailModalOpen = action.payload
		},
		setIsAccountsLoaded: (state, action) => {
			state.isAccountsLoaded = action.payload
		},
	},
	extraReducers: {
		[saveAccount.pending]: (state, action) => {
			state.status = 'loading'
		},
		[saveAccount.fulfilled]: (state, action) => {
			state.status = 'succeeded'
		},
		[saveAccount.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

export const fetchAccounts = venueId => (dispatch, getState) => {
	return onSnapshot(
		query(collection(firestore, 'accounts'), where('etablissement', '==', venueId), where('toLoad', '==', true)),
		querySnapshot => {
			const accounts = querySnapshot.docs.map(doc => {
				const { lastReservationDate, ...data } = doc.data()
				return { ...data, id: doc.id }
			})
			dispatch(setIsAccountsLoaded(true))

			if (getState().accounts.value.length === 0) {
				dispatch(setAccounts(accounts))
			} else {
				dispatch(mergeAccounts(accounts))
			}
		},
		error => {
			console.log(error)
		}
	)
}

export const loadAccountFromId = async accountId => {
	const docSnap = await getDoc(doc(firestore, 'accounts', accountId))
	const { lastReservationDate, ...data } = docSnap.data()
	return { ...data, id: docSnap.id }
}

export const fetchAccountsByBatches = (accountsIds, venueId) => {
	const arrAccountsIds = accountsIds.reduce((res, _, index) => {
		if (index > 0 && index % 10 === 0) {
			const arr = accountsIds.slice(10 * (index / 10 - 1), index)
			const promise = getDocs(query(collection(firestore, 'accounts'), where(documentId(), 'in', arr), where('etablissement', '==', venueId)))
			return [...res, promise]
		} else if (index === accountsIds.length - 1) {
			const arr = accountsIds.slice(accountsIds.length - 1 - (index % 10))
			const promise = getDocs(query(collection(firestore, 'accounts'), where(documentId(), 'in', arr), where('etablissement', '==', venueId)))
			return [...res, promise]
		}
		return res
	}, [])
	// const residual = accountsIds.slice(-accountsIds.length % 10)
	// console.log('d',residual.length)
	// residual.length > 0 && arrAccountsIds.push(getDocs(query(collection(firestore, 'accounts'), where(documentId(), 'in', residual))))

	return Promise.all(arrAccountsIds)
}

export const getNewAccountId = () => {
	return doc(collection(firestore, 'accounts')).id
}

export const getAccounts = state => state.accounts.value
export const getAccountStatus = state => state.accounts.status
export const getSelectedAccount = state => state.accounts.selectedAccount
export const getIsAccountDetailModalOpen = state => state.accounts.isAccountDetailModalOpen
export const getIsAccountsLoaded = state => state.accounts.isAccountsLoaded

export const { updateAccountInAccounts, mergeAccounts, setAccounts, setAccountStatus, setSelectedAccount, setIsAccountDetailModalOpen, setIsAccountsLoaded } = accountsSlice.actions

export default accountsSlice.reducer
