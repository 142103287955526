import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../../backend'
import { collection, doc, updateDoc, query, where, setDoc, getDocs } from 'firebase/firestore'

export const saveSlot = createAsyncThunk('slots/saveSlot', async ({ id, data }) => {
	if (id) {
		return updateDoc(doc(firestore, 'slots', id), data)
	} else {
		const slotId = doc(collection(firestore, 'slots')).id
		return setDoc(doc(firestore, 'slots', slotId), data, { merge: true })
	}
})

export const fetchSlots = createAsyncThunk('slots/fetchSlots', async (venueId, { dispatch }) => {
	try {
		const querySnapshot = await getDocs(query(collection(firestore, 'slots'), where('etablissement', '==', venueId)))
		const slots = querySnapshot.docs
			.map(doc => ({ ...doc.data(), id: doc.id }))
			.sort((a, b) => {
				if (b.heureDebut && a.heureDebut) {
					if (parseFloat(a.heureDebut.replace(',', '.')) > parseFloat(b.heureDebut.replace(',', '.'))) return 1
					if (parseFloat(a.heureDebut.replace(',', '.')) < parseFloat(b.heureDebut.replace(',', '.'))) return -1
				}
				return 0
			})
		dispatch(setSlots(slots))
	} catch (error) {
		console.log(error)
	}
})

export const slotsSlice = createSlice({
	name: 'slots',
	initialState: {
		value: [],
		status: 'idle',
		error: null,
	},
	reducers: {
		setSlots: (state, action) => {
			state.value = action.payload
		},
		setSlotStatus: (state, action) => {
			state.status = action.payload
		},
	},
	extraReducers: {
		[saveSlot.pending]: (state, action) => {
			state.status = 'loading'
		},
		[saveSlot.fulfilled]: (state, action) => {
			state.status = 'succeeded'
		},
		[saveSlot.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

// export const fetchSlots = venueId => dispatch => {
// 	return onSnapshot(
// 		query(collection(firestore, 'slots'), where('etablissement', '==', venueId)),
// 		querySnapshot => {
// 			const slots = querySnapshot.docs
// 				.map(doc => ({ ...doc.data(), id: doc.id }))
// 				.sort((a, b) => {
// 					if (b.heureDebut && a.heureDebut) {
// 						if (parseFloat(a.heureDebut.replace(',', '.')) > parseFloat(b.heureDebut.replace(',', '.'))) return 1
// 						if (parseFloat(a.heureDebut.replace(',', '.')) < parseFloat(b.heureDebut.replace(',', '.'))) return -1
// 					}
// 					return 0
// 				})
// 			dispatch(setIsSlotsLoaded(true))
// 			dispatch(setSlots(slots))
// 		},
// 		error => {
// 			console.log(error)
// 		}
// 	)
// }

export const getSlots = state => state.slots.value
export const getSlotsStatus = state => state.slots.status

export const { setSlots, setSlotStatus } = slotsSlice.actions

export default slotsSlice.reducer
