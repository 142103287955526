import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../../backend'
import { collection, query, where, updateDoc, doc, setDoc, getDocs } from 'firebase/firestore'

export const saveVariable = createAsyncThunk('variables/saveVariable', async ({ id, data }) => {
	if (id) {
		return updateDoc(doc(firestore, 'variables', id), data)
	} else {
		const variableId = doc(collection(firestore, 'variables')).id
		return setDoc(doc(firestore, 'variables', variableId), data, { merge: true })
	}
})

export const fetchVariables = createAsyncThunk('variables/fetchVariables', async (venueId, { dispatch }) => {
	try {
		const querySnapshot = await getDocs(query(collection(firestore, 'variables'), where('etablissement', '==', venueId)))
		const variables = querySnapshot.docs
			.map(doc => ({ ...doc.data(), id: doc.id }))
			.sort((a, b) => {
				if (a.name > b.name) return 1
				if (a.name < b.name) return -1
				return 0
			})
		dispatch(setVariables(variables))
	} catch (error) {
		console.log(error)
	}
})

export const fetchVariablesOfVenue = async venueId => {
	const querySnapshot = await getDocs(query(collection(firestore, 'variables'), where('etablissement', '==', venueId)))
	const variables = querySnapshot.docs
		.map(doc => ({ ...doc.data(), id: doc.id }))
		.sort((a, b) => {
			if (a.name > b.name) return 1
			if (a.name < b.name) return -1
			return 0
		})
	return variables
}

export const fetchGlobalVariables = createAsyncThunk('variables/fetchGlobalVariables', async (_, { dispatch }) => {
	try {
		const querySnapshot = await getDocs(collection(firestore, 'global_variables'))
		const globalVariables = querySnapshot.docs
			.map(doc => ({ ...doc.data(), id: doc.id }))
			.sort((a, b) => {
				if (a.publicName > b.publicName) return 1
				if (a.publicName < b.publicName) return -1
				return 0
			})
		dispatch(setGlobalVariables(globalVariables))
	} catch (error) {
		console.log(error)
	}
})

export const variablesSlice = createSlice({
	name: 'variables',
	initialState: {
		value: [],
		globalVariables: [],
		status: 'idle',
		error: null,
	},
	reducers: {
		setVariables: (state, action) => {
			state.value = action.payload
		},
		setVariableStatus: (state, action) => {
			state.status = action.payload
		},
		setGlobalVariables: (state, action) => {
			state.globalVariables = action.payload
		},
	},
	extraReducers: {
		[saveVariable.pending]: (state, action) => {
			state.status = 'loading'
		},
		[saveVariable.fulfilled]: (state, action) => {
			state.status = 'succeeded'
		},
		[saveVariable.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

// export const fetchVariables = venueId => dispatch => {
// 	return onSnapshot(
// 		query(collection(firestore, 'variables'), where('etablissement', '==', venueId)),
// 		querySnapshot => {
// 			const variables = querySnapshot.docs
// 				.map(doc => ({ ...doc.data(), id: doc.id }))
// 				.sort((a, b) => {
// 					if (a.name > b.name) return 1
// 					if (a.name < b.name) return -1
// 					return 0
// 				})

// 			dispatch(setIsVariablesLoaded(true))
// 			dispatch(setVariables(variables))
// 		},
// 		error => {
// 			console.log(error)
// 		}
// 	)
// }

// export const fetchGlobalVariables = () => dispatch => {
// 	return getDocs(collection(firestore, 'global_variables'))
// 		.then(querySnapshot => {
// 			const globalVariables = querySnapshot.docs
// 				.map(doc => ({ ...doc.data(), id: doc.id }))
// 				.sort((a, b) => {
// 					if (a.publicName > b.publicName) return 1
// 					if (a.publicName < b.publicName) return -1
// 					return 0
// 				})
// 			dispatch(setGlobalVariables(globalVariables))
// 		})
// 		.catch(error => {
// 			console.log(error)
// 		})
// }

export const getVariables = state => state.variables.value
export const getVariableStatus = state => state.variables.status
export const getGlobalVariables = state => state.variables.globalVariables

export const { setVariables, setVariableStatus, setGlobalVariables } = variablesSlice.actions

export default variablesSlice.reducer
