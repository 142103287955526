import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../../backend'
import dayjs from 'dayjs'
import { collection, doc, updateDoc, query, where, onSnapshot, setDoc } from 'firebase/firestore'

export const saveCustomDate = createAsyncThunk('customDates/saveCustomDate', async ({ id, data }) => {
	if (id) {
		return updateDoc(doc(firestore, 'customDates', id), data)
	} else {
		const customDateId = doc(collection(firestore, 'customDates')).id
		return setDoc(doc(firestore, 'customDates', customDateId), data, { merge: true })
	}
})

export const customDatesSlice = createSlice({
	name: 'customDates',
	initialState: {
		value: [],
		status: 'idle',
		isCustomDatesLoaded: false,
		error: null,
	},
	reducers: {
		setCustomDates: (state, action) => {
			state.value = action.payload
		},
		setCustomDateStatus: (state, action) => {
			state.status = action.payload
		},
		setIsCustomDatesLoaded: (state, action) => {
			state.isCustomDatesLoaded = action.payload
		},
	},
	extraReducers: {
		[saveCustomDate.pending]: (state, action) => {
			state.status = 'loading'
		},
		[saveCustomDate.fulfilled]: (state, action) => {
			state.status = 'succeeded'
		},
		[saveCustomDate.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

export const fetchCustomDates = venueId => dispatch => {
	const threshold = dayjs().startOf('month').startOf('week').toDate()

	return onSnapshot(
		query(collection(firestore, 'customDates'), where('date', '>=', threshold), where('etablissement', '==', venueId)),
		querySnapshot => {
			const customDates = querySnapshot.docs.map(doc => {
				const { date, ...data } = doc.data()
				// return { ...data, id: doc.id, unixDate: data.unixDate || date.seconds }
				return { ...data, id: doc.id, unixDate: date.seconds }
			})
			dispatch(setIsCustomDatesLoaded(true))
			dispatch(setCustomDates(customDates))
		},
		error => {
			console.log(error)
		}
	)
}

export const getCustomDates = state => state.customDates.value
export const getCustomDatesStatus = state => state.customDates.status
export const getIsCustomDatesLoaded = state => state.customDates.isCustomDatesLoaded

export const { setCustomDates, setCustomDateStatus, setIsCustomDatesLoaded } = customDatesSlice.actions

export default customDatesSlice.reducer
