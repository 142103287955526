import { initializeApp } from 'firebase/app'
import { initializeFirestore, writeBatch, doc, runTransaction } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'

const config = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: `https://${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSENGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

export const app = initializeApp(config)

if (window.location.hostname === 'localhost') {
	window.FIREBASE_APPCHECK_DEBUG_TOKEN = 'BDADC4A4-ECCC-4252-A72E-5DD8EF90C653'
}

initializeAppCheck(app, {
	provider: new ReCaptchaV3Provider('6LcIVtccAAAAAG79NGEE_Rv-Gh82dXAig3j9vbLz'),
	isTokenAutoRefreshEnabled: true,
})

export const firestore = initializeFirestore(app, { ignoreUndefinedProperties: true })
export const storage = getStorage(app)
export const runFirestoreTransaction = runTransaction
export const createFirestoreRef = (collection, id) => doc(firestore, collection, id)
const functions = getFunctions(app)
const functionsV2 = getFunctions(app, 'europe-west1')

// export const STRIPE_KEY = 'pk_live_prSXyfHVaVAJPw4inFdXYDMu'
/*const STRIPE_KEY = 'pk_test_6QSI5yrkcHNhLudWDJL6wxd8'*/
export const httpDomain = 'https://keoydqnp9l.execute-api.eu-west-3.amazonaws.com/dev/'

export const msConfig = {
	appId: '3b3739d2-25f9-4599-bb95-c251ac8b15bd',
}

export const createBatch = () => {
	return writeBatch(firestore)
}

export const getCallableFunction = (functionName, options = undefined) => {
	return httpsCallable(functions, functionName, options)
}

export const getCallableFunctionV2 = (functionName, options = undefined) => {
	return httpsCallable(functionsV2, functionName, options)
}

export const handleBatch = (batch, collection, id, data, type = 'update') => {
	if (type === 'update') {
		batch.update(doc(firestore, collection, id), data)
	} else if (type === 'set') {
		batch.set(doc(firestore, collection, id), data, { merge: true })
	}
}
