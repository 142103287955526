import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../../backend'
import { collection, doc, updateDoc, query, where, onSnapshot, setDoc } from 'firebase/firestore'

export const saveLink = createAsyncThunk('links/saveLink', async ({ id, data }) => {
	if (id) {
		return updateDoc(doc(firestore, 'links', id), data)
	} else {
		const linkId = doc(collection(firestore, 'links')).id
		return setDoc(doc(firestore, 'links', linkId), data, { merge: true })
	}
})

export const linksSlice = createSlice({
	name: 'links',
	initialState: {
		value: [],
		status: 'idle',
		isLinksLoaded: false,
		error: null,
	},
	reducers: {
		setLinks: (state, action) => {
			state.value = action.payload
		},
		setLinkStatus: (state, action) => {
			state.status = action.payload
		},
		setIsLinksLoaded: (state, action) => {
			state.isLinksLoaded = action.payload
		},
	},
	extraReducers: {
		[saveLink.pending]: (state, action) => {
			state.status = 'loading'
		},
		[saveLink.fulfilled]: (state, action) => {
			state.status = 'succeeded'
		},
		[saveLink.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

export const fetchLinks = venueId => dispatch => {
	return onSnapshot(
		query(collection(firestore, 'links'), where('etablissement', '==', venueId), where('status', '!=', 'hide')),
		querySnapshot => {
			const links = querySnapshot.docs
				.map(doc => ({ ...doc.data(), id: doc.id }))
				.sort((a, b) => {
					if (a.creationDate > b.creationDate) return -1
					if (a.creationDate < b.creationDate) return 1
					return 0
				})

			dispatch(setIsLinksLoaded(true))
			dispatch(setLinks(links))
		},
		error => {
			console.log(error)
		}
	)
}

export const getNewLinkId = () => {
	return doc(collection(firestore, 'links')).id
}

export const setLinkWithId = (id, data) => {
	return setDoc(doc(firestore, 'links', id), data, { merge: true })
}

export const getLinks = state => state.links.value
export const getLinkStatus = state => state.links.status
export const getIsLinksLoaded = state => state.links.isLinksLoaded

export const { setLinks, setLinkStatus, setIsLinksLoaded } = linksSlice.actions

export default linksSlice.reducer
