import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../../backend'
import dayjs from 'dayjs'
import { collection, doc, updateDoc, setDoc, query, where, onSnapshot, orderBy, getDocs, writeBatch, getDoc, or, and } from 'firebase/firestore'

export const saveBooking = createAsyncThunk('bookings/saveBooking', async ({ id, data }) => {
	return updateDoc(doc(firestore, 'reservations', id), data)
})

export const bookingsSlice = createSlice({
	name: 'bookings',
	initialState: {
		value: [],
		specialValue: [],
		status: 'idle',
		isBookingsLoaded: false,
		isBookingsSpecialLoaded: false,
		error: null,
		selectedBooking: {},
		isBookingDrawerOpen: false,
		isBookingModalOpen: false,
		selectedDate: dayjs().format('DD-MM-YYYY'),
		bookingDataToImport: null,
	},
	reducers: {
		setBookings: (state, action) => {
			state.value = action.payload.data
		},
		setSpecialBookings: (state, action) => {
			state.specialValue = action.payload.data
		},
		mergeBookings: (state, action) => {
			state.value = [...state.value.filter(({ id }) => !action.payload.data.find(obj => obj.id === id)), ...action.payload.data]
		},
		updateBookingInBookings: (state, action) => {
			const idx = state.value.findIndex(({ id }) => id === action.payload.id)
			state.value[idx] = action.payload
		},
		resetBookings: state => {
			state.value = []
			state.specialValue = []
		},
		updateBookingsInBookings: (state, action) => {
			action.payload.forEach(booking => {
				const idx = state.value.findIndex(({ id }) => id === booking.id)
				state.value[idx] = booking
			})
		},
		setBookingStatus: (state, action) => {
			state.status = action.payload
		},
		setSelectedBooking: (state, action) => {
			state.selectedBooking = action.payload
		},
		setIsBookingDrawerOpen: (state, action) => {
			state.isBookingDrawerOpen = action.payload
		},
		setIsBookingModalOpen: (state, action) => {
			state.isBookingModalOpen = action.payload
		},
		setSelectedDate: (state, action) => {
			state.selectedDate = action.payload
		},
		setIsBookingsLoaded: (state, action) => {
			state.isBookingsLoaded = action.payload
		},
		setIsBookingsSpecialLoaded: (state, action) => {
			state.isBookingsSpecialLoaded = action.payload
		},
		setBookingDataToImport: (state, action) => {
			state.bookingDataToImport = action.payload
		},
	},
	extraReducers: {
		[saveBooking.pending]: state => {
			state.status = 'loading'
		},
		[saveBooking.fulfilled]: state => {
			state.status = 'succeeded'
		},
		[saveBooking.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

export const fetchBookings = venueId => (dispatch, getState) => {
	const threshold = dayjs().startOf('month').startOf('week').toDate()
	const status = getState().status.value
	const pseudoDoneIds = status.filter(({ type }) => type === 'pseudo_done').map(({ id }) => id)
	const bookingsQuery = pseudoDoneIds.length > 0 ? query(collection(firestore, 'reservations'), and(where('etablissement', '==', venueId), or(where('dateDB', '>=', threshold), where('statusId', 'in', pseudoDoneIds)))) : query(collection(firestore, 'reservations'), where('etablissement', '==', venueId), where('dateDB', '>=', threshold))

	return onSnapshot(
		// query(collection(firestore, 'reservations'), where('etablissement', '==', venueId), where('dateDB', '>=', threshold)),
		bookingsQuery,
		querySnapshot => {
			const bookings = querySnapshot.docs.reduce((res, doc) => {
				const { dateDB, ...data } = doc.data()
				return [...res, { ...data, id: doc.id }]
			}, [])

			const statusIds = getState()
				.status.value.filter(({ type }) => ['canceled', 'archived'].indexOf(type) === -1)
				.map(({ id }) => id)

			dispatch(setIsBookingsLoaded(true))
			dispatch(setBookings({ data: bookings.filter(({ statusId }) => statusIds.indexOf(statusId) > -1) }))
			// dispatch(setBookings({ data: bookings }))
		},
		error => {
			console.log(error)
			dispatch(setIsBookingsLoaded(true))
		}
	)
}

export const fetchBookingsOfMonth = (venueId, month) => {
	const thresholdStart = dayjs(month, 'MMMM YYYY').startOf('month').startOf('week').toDate()
	const thresholdEnd = dayjs(month, 'MMMM YYYY').endOf('month').endOf('week').toDate()

	return getDocs(query(collection(firestore, 'reservations'), where('etablissement', '==', venueId), where('dateDB', '>=', thresholdStart), where('dateDB', '<=', thresholdEnd)))
}

export const fetchBookingsOfWeek = (venueId, week) => {
	const thresholdStart = dayjs(week, 'DD-MM-YYYY').startOf('week').toDate()
	const thresholdEnd = dayjs(week, 'DD-MM-YYYY').endOf('week').toDate()

	return getDocs(query(collection(firestore, 'reservations'), where('etablissement', '==', venueId), where('dateDB', '>=', thresholdStart), where('dateDB', '<=', thresholdEnd)))
}

export const fetchBookingsOfDay = (venueId, date) => {
	return getDocs(query(collection(firestore, 'reservations'), where('etablissement', '==', venueId), where('date', '==', date)))
}

export const fetchBookingsOfClients = async (clients, venueId) => {
	const arrProm = []
	for (let index = 0; index < Math.ceil(clients.length / 10); index++) {
		arrProm.push(getDocs(query(collection(firestore, 'reservations'), where('etablissement', '==', venueId), where('client', 'in', clients.slice(index * 10, (index + 1) * 10)), orderBy('dateDB', 'desc'))))
	}
	const req = await Promise.all(arrProm)
	return req.reduce((res, curr) => [...res, ...curr.docs.map(doc => ({ ...doc.data(), id: doc.id }))], [])
}

export const fetchBookingsOfClientId = async (clientId, venueId) => {
	const req = await getDocs(query(collection(firestore, 'reservations'), where('etablissement', '==', venueId), where('client', '==', clientId), orderBy('dateDB', 'desc')))
	return req.docs.map(doc => ({ ...doc.data(), id: doc.id }))
}

export const updateBookingsByBatch = (bookings, data) => {
	const batch = writeBatch(firestore)
	bookings.forEach(booking => {
		batch.update(doc(firestore, 'reservations', booking.id), data)
	})
	return batch.commit()
}

export const updateBooking = (id, data) => {
	return updateDoc(doc(firestore, 'reservations', id), data)
}

export const getBookingFromId = async id => {
	const snapDoc = await getDoc(doc(firestore, 'reservations', id))
	const { dateDB, ...rest } = snapDoc.data()
	return { ...rest, id: snapDoc.id }
}

export const getBookingsFromGroupId = async (groupId, status, venueId) => {
	const cancelIds = status.filter(({ type }) => type === 'canceled' || type === 'archived').map(({ id }) => id)
	const querySnapshot = await getDocs(query(collection(firestore, 'reservations'), where('etablissement', '==', venueId), where('groupId', '==', groupId)))
	if (querySnapshot.docs.filter(doc => cancelIds.indexOf(doc.data().statusId) === -1).length === 0) {
		return querySnapshot.docs.map(doc => {
			const { dateDB, ...rest } = doc.data()
			return { ...rest, id: doc.id }
		})
	}
	return querySnapshot.docs
		.filter(doc => cancelIds.indexOf(doc.data().statusId) === -1)
		.map(doc => {
			const { dateDB, ...rest } = doc.data()
			return { ...rest, id: doc.id }
		})
}

export const getNewBookingId = () => {
	return doc(collection(firestore, 'bookings')).id
}

export const addNewBooking = (bId, bData) => {
	return setDoc(doc(firestore, 'reservations', bId), bData, { merge: true })
}

export const getBookings = state => {
	// const statusIds = state.status.value.filter(({ type }) => type === 'pseudo_done').map(({ id }) => id)
	// return [...state.bookings.value.filter(({ statusId }) => statusIds.indexOf(statusId) === -1), ...state.bookings.specialValue]
	return state.bookings.value
}
export const getBookingStatus = state => state.bookings.status
export const getSelectedBooking = state => state.bookings.selectedBooking
export const getIsBookingDrawerOpen = state => state.bookings.isBookingDrawerOpen
export const getIsBookingModalOpen = state => state.bookings.isBookingModalOpen
export const getSelectedDate = state => state.bookings.selectedDate
export const getIsBookingsLoaded = state => state.bookings.isBookingsLoaded
export const getIsBookingsSpecialLoaded = state => state.bookings.isBookingsSpecialLoaded
export const getBookingDataToImport = state => state.bookings.bookingDataToImport

export const { updateBookingsInBookings, updateBookingInBookings, setBookingStatus, setSelectedBooking, setIsBookingDrawerOpen, setIsBookingModalOpen, setSelectedDate, setIsBookingsLoaded, setIsBookingsSpecialLoaded, mergeBookings, resetBookings, setBookings, setSpecialBookings, setBookingDataToImport } = bookingsSlice.actions

export default bookingsSlice.reducer
