import { createSlice } from '@reduxjs/toolkit'
import { firestore } from '../../backend'
import { collection, query, where, getDocs } from 'firebase/firestore'

export const statisticsSlice = createSlice({
	name: 'statistics',
	initialState: {
		value: [],
		isStatisticsLoaded: false,
		error: null,
	},
	reducers: {
		setStatistics: (state, action) => {
			state.value = action.payload
		},
		setIsStatisticsLoaded: (state, action) => {
			state.isStatisticsLoaded = action.payload
		},
	},
})

export const fetchStatistics = venueId => dispatch => {
	return getDocs(query(collection(firestore, 'statistiques'), where('etablissement', '==', venueId))).then(querySnapshot => {
		const statistics = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
		dispatch(setStatistics(statistics))
	})
}

export const getStatistics = state => state.statistics.value
export const getIsStatisticsLoaded = state => state.statistics.isStatisticsLoaded

export const { setStatistics, setTemplateStatus, setIsStatisticsLoaded } = statisticsSlice.actions

export default statisticsSlice.reducer
