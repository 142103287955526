import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../../backend'
import { collection, query, where, updateDoc, doc, setDoc, getDocs } from 'firebase/firestore'

export const saveTemplate = createAsyncThunk('templates/saveTemplate', async ({ id, data }) => {
	if (id) {
		return updateDoc(doc(firestore, 'templates', id), data)
	} else {
		const templateId = doc(collection(firestore, 'templates')).id
		return setDoc(doc(firestore, 'templates', templateId), data, { merge: true })
	}
})

export const fetchTemplates = createAsyncThunk('templates/fetchTemplates', async (venueId, { dispatch }) => {
	try {
		const querySnapshot = await getDocs(query(collection(firestore, 'templates'), where('etablissement', '==', venueId)))
		const templates = querySnapshot.docs
			.map(doc => ({ ...doc.data(), id: doc.id }))
			.sort((a, b) => {
				if (a.nom > b.nom) return 1
				if (a.nom < b.nom) return -1
				return 0
			})
		dispatch(setTemplates(templates))
	} catch (error) {
		console.log(error)
	}
})

export const fetchTemplatesOfVenue = async venueId => {
	const querySnapshot = await getDocs(query(collection(firestore, 'templates'), where('etablissement', '==', venueId)))
	const templates = querySnapshot.docs
		.map(doc => ({ ...doc.data(), id: doc.id }))
		.sort((a, b) => {
			if (a.nom > b.nom) return 1
			if (a.nom < b.nom) return -1
			return 0
		})
	return templates
}

export const templatesSlice = createSlice({
	name: 'templates',
	initialState: {
		value: [],
		status: 'idle',
		error: null,
	},
	reducers: {
		setTemplates: (state, action) => {
			state.value = action.payload
		},
		setTemplateStatus: (state, action) => {
			state.status = action.payload
		},
	},
	extraReducers: {
		[saveTemplate.pending]: (state, action) => {
			state.status = 'loading'
		},
		[saveTemplate.fulfilled]: (state, action) => {
			state.status = 'succeeded'
		},
		[saveTemplate.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

// export const fetchTemplates = async venueId => async dispatch => {
// 	return onSnapshot(
// 		query(collection(firestore, 'templates'), where('etablissement', '==', venueId)),
// 		querySnapshot => {
// 			const templates = querySnapshot.docs
// 				.map(doc => ({ ...doc.data(), id: doc.id }))
// 				.sort((a, b) => {
// 					if (a.nom > b.nom) return 1
// 					if (a.nom < b.nom) return -1
// 					return 0
// 				})
// 			dispatch(setIsTemplatesLoaded(true))
// 			dispatch(setTemplates(templates))
// 		},
// 		error => {
// 			console.log(error)
// 		}
// 	)
// }

export const getTemplates = state => state.templates.value
export const getTemplateStatus = state => state.templates.status

export const { setTemplates, setTemplateStatus } = templatesSlice.actions

export default templatesSlice.reducer
