import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../../backend'
import dayjs from 'dayjs'
import { collection, doc, updateDoc, query, where, onSnapshot, setDoc, writeBatch } from 'firebase/firestore'

export const savePayment = createAsyncThunk('payments/savePayment', async ({ id, data }) => {
	if (id) {
		return updateDoc(doc(firestore, 'payments', id), data)
	} else {
		const paymentId = doc(collection(firestore, 'payments')).id
		return setDoc(doc(firestore, 'payments', paymentId), { ...data, creationDate: dayjs().unix() }, { merge: true })
	}
})

export const paymentsSlice = createSlice({
	name: 'payments',
	initialState: {
		value: [],
		status: 'idle',
		error: null,
		paymentsOfBooking: [],
	},
	reducers: {
		setPaymentsOfBooking: (state, action) => {
			state.paymentsOfBooking = action.payload
		},
		setPaymentsStatus: (state, action) => {
			state.status = action.payload
		},
	},
	extraReducers: {
		[savePayment.pending]: (state, action) => {
			state.status = 'loading'
		},
		[savePayment.fulfilled]: (state, action) => {
			state.status = 'succeeded'
		},
		[savePayment.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

export const fetchPaymentsOfBooking = (bookingId, venueId) => dispatch => {
	return onSnapshot(
		query(collection(firestore, 'payments'), where('reservation', '==', bookingId), where('etablissement', '==', venueId)),
		querySnapshot => {
			const payments = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })).filter(({ status }) => status !== 'canceled')
			dispatch(setPaymentsOfBooking(payments))
		},
		error => {
			console.log(error)
		}
	)
}

export const fetchPaymentsOfGroup = (groupId, venueId) => dispatch => {
	return onSnapshot(
		query(collection(firestore, 'payments'), where('groupId', '==', groupId), where('etablissement', '==', venueId)),
		querySnapshot => {
			const payments = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })).filter(({ status }) => status !== 'canceled')
			dispatch(setPaymentsOfBooking(payments))
		},
		error => {
			console.log(error)
		}
	)
}

export const updatePayments = data => async (_, getState) => {
	const payments = getState().payments.paymentsOfBooking
	const batch = writeBatch(firestore)

	payments.forEach(payment => {
		batch.update(doc(firestore, 'payments', payment.id), data)
	})

	return batch.commit()
}

export const getPaymentsOfBooking = state => state.payments.paymentsOfBooking
export const getPaymentsStatus = state => state.payments.status

export const { setPaymentsOfBooking, setPaymentsStatus } = paymentsSlice.actions

export default paymentsSlice.reducer
