import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../../backend'
import { collection, doc, updateDoc, query, where, setDoc, getDocs } from 'firebase/firestore'

export const saveCustomField = createAsyncThunk('customFields/saveCustomField', async ({ id, data }) => {
	if (id) {
		return updateDoc(doc(firestore, 'custom_fields', id), data)
	} else {
		const customFieldId = doc(collection(firestore, 'custom_fields')).id
		return setDoc(doc(firestore, 'custom_fields', customFieldId), data, { merge: true })
	}
})

export const fetchCustomFields = createAsyncThunk('customFields/fetchCustomFields', async ({ venueIds = [], venueId }, { dispatch }) => {
	try {
		if (venueIds.length > 1) {
			const req = await Promise.all(Array.from({ length: Math.ceil(venueIds.length / 10) }).map((_, index) => getDocs(query(collection(firestore, 'custom_fields'), where('etablissement', 'in', venueIds.slice(index * 10, (index + 1) * 10))))))
			const custom_fields = req
				.reduce((res, querySnapshot) => [...res, ...querySnapshot.docs.filter(doc => !doc.data().isDeleted).map(doc => ({ ...doc.data(), id: doc.id }))], [])
				.sort((a, b) => {
					if (!b.order) return -1
					if (a.order > b.order) return 1
					if (a.order < b.order) return -1
					return 0
				})

			dispatch(setCustomFields(custom_fields.filter(({ etablissement }) => etablissement === venueId)))
			dispatch(setAllCustomFields(custom_fields))
		} else {
			const querySnapshot = await getDocs(query(collection(firestore, 'custom_fields'), where('etablissement', '==', venueId)))
			const customFields = querySnapshot.docs
				.filter(doc => !doc.data().isDeleted)
				.map(doc => ({ ...doc.data(), id: doc.id }))
				.sort((a, b) => {
					if (!b.order) return -1
					if (a.order > b.order) return 1
					if (a.order < b.order) return -1
					return 0
				})

			dispatch(setCustomFields(customFields))
		}
	} catch (error) {
		console.log(error)
	}
})

export const customFieldsSlice = createSlice({
	name: 'customFields',
	initialState: {
		value: [],
		status: 'idle',
		error: null,
		all_custom_fields: [],
	},
	reducers: {
		setCustomFields: (state, action) => {
			state.value = action.payload
		},
		setCustomFieldStatus: (state, action) => {
			state.status = action.payload
		},
		setAllCustomFields: (state, action) => {
			state.all_custom_fields = action.payload
		},
	},
	extraReducers: {
		[saveCustomField.pending]: (state, action) => {
			state.status = 'loading'
		},
		[saveCustomField.fulfilled]: (state, action) => {
			state.status = 'succeeded'
		},
		[saveCustomField.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

export const getCustomFields = state => state.customFields.value
export const getCustomFieldStatus = state => state.customFields.status
export const getAllCustomFields = state => state.customFields.all_custom_fields

export const { setCustomFields, setCustomFieldStatus, setAllCustomFields } = customFieldsSlice.actions

export default customFieldsSlice.reducer
