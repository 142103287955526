import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../../backend'
import { collection, doc, updateDoc, query, where, onSnapshot, increment, writeBatch } from 'firebase/firestore'

export const saveBase = createAsyncThunk('bases/saveBase', async ({ id, data }) => {
	if (id) {
		return updateDoc(doc(firestore, 'bases', id), data)
	} else {
		const baseId = doc(collection(firestore, 'bases')).id
		const batch = writeBatch(firestore)
		batch.set(doc(firestore, 'bases', baseId), data)

		const { etablissement, typeDocument } = data
		if (etablissement) {
			if (typeDocument === 'devis' || typeDocument === 'base') {
				batch.update(doc(firestore, 'etablissements', etablissement), { countDevis: increment(1) })
			}
		}
		return batch.commit()
	}
})

export const basesSlice = createSlice({
	name: 'bases',
	initialState: {
		value: [],
		status: 'idle',
		error: null,
		basesOfBooking: [],
	},
	reducers: {
		setBasesOfBooking: (state, action) => {
			state.basesOfBooking = action.payload
		},
		setBasesStatus: (state, action) => {
			state.status = action.payload
		},
	},
	extraReducers: {
		[saveBase.pending]: (state, action) => {
			state.status = 'loading'
		},
		[saveBase.fulfilled]: (state, action) => {
			state.status = 'succeeded'
		},
		[saveBase.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

export const fetchBasesOfBooking = (bookingId, venueId) => dispatch => {
	return onSnapshot(
		query(collection(firestore, 'bases'), where('reservation', '==', bookingId), where('etablissement', '==', venueId)),
		querySnapshot => {
			const bases = querySnapshot.docs
				.map(doc => {
					const { dateDB, ...data } = doc.data()
					return { ...data, id: doc.id }
				})
				.sort((a, b) => {
					if (a.creationDate > b.creationDate) return -1
					if (a.creationDate < b.creationDate) return 1
					return 0
				})
			dispatch(setBasesOfBooking(bases))
		},
		error => {
			console.log(error)
		}
	)
}

export const fetchBasesOfGroup = (groupId, venueId) => dispatch => {
	return onSnapshot(
		query(collection(firestore, 'bases'), where('groupId', '==', groupId), where('etablissement', '==', venueId)),
		querySnapshot => {
			const bases = querySnapshot.docs
				.map(doc => {
					const { dateDB, ...data } = doc.data()
					return { ...data, id: doc.id }
				})
				.sort((a, b) => {
					if (a.creationDate > b.creationDate) return -1
					if (a.creationDate < b.creationDate) return 1
					return 0
				})
			dispatch(setBasesOfBooking(bases))
		},
		error => {
			console.log(error)
		}
	)
}

export const updateBases = data => async (_, getState) => {
	const bases = getState().bases.basesOfBooking
	const batch = writeBatch(firestore)

	bases.forEach(base => {
		batch.update(doc(firestore, 'bases', base.id), data)
	})

	return batch.commit()
}

export const updateBatchBases = bases => {
	const batch = writeBatch(firestore)

	bases.forEach(({ id, data }) => {
		batch.update(doc(firestore, 'bases', id), data)
	})

	return batch.commit()
}

export const getNewBaseId = () => {
	return doc(collection(firestore, 'bases')).id
}

export const getBasesOfBooking = state => state.bases.basesOfBooking
export const getBasesStatus = state => state.bases.status

export const { setBasesOfBooking, setBasesStatus } = basesSlice.actions

export default basesSlice.reducer
