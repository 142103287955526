import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../../backend'
import dayjs from 'dayjs'
import { collection, doc, updateDoc, query, where, onSnapshot, writeBatch, setDoc } from 'firebase/firestore'

export const saveRoom = createAsyncThunk('rooms/saveRoom', async ({ id, data }) => {
	if (id) {
		return updateDoc(doc(firestore, 'rooms', id), data)
	} else {
		const roomId = doc(collection(firestore, 'rooms')).id
		return setDoc(doc(firestore, 'rooms', roomId), { ...data, creationDate: dayjs().unix() }, { merge: true })
	}
})

export const roomsSlice = createSlice({
	name: 'rooms',
	initialState: {
		value: [],
		status: 'idle',
		roomsOfBooking: [],
		isRoomsLoaded: false,
		error: null,
	},
	reducers: {
		setRooms: (state, action) => {
			state.value = action.payload.data
		},
		setRoomStatus: (state, action) => {
			state.status = action.payload.data
		},
		setIsRoomsLoaded: (state, action) => {
			state.isRoomsLoaded = action.payload
		},
		setRoomsOfBooking: (state, action) => {
			state.roomsOfBooking = action.payload
		},
	},
	extraReducers: {
		[saveRoom.pending]: state => {
			state.status = 'loading'
		},
		[saveRoom.fulfilled]: state => {
			state.status = 'succeeded'
		},
		[saveRoom.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

export const fetchRooms = venueId => dispatch => {
	const threshold = dayjs().startOf('month').startOf('week').unix()

	return onSnapshot(
		query(collection(firestore, 'rooms'), where('etablissement', '==', venueId), where('dateUnix', '>=', threshold)),
		querySnapshot => {
			const rooms = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })).filter(({ isDeleted }) => !isDeleted)

			dispatch(setIsRoomsLoaded(true))
			dispatch(setRooms({ data: rooms }))
		},
		error => {
			console.log(error)
			dispatch(setIsRoomsLoaded(true))
		}
	)
}

export const fetchRoomsOfBooking = (bookingId, venueId) => dispatch => {
	return onSnapshot(
		query(collection(firestore, 'rooms'), where('reservation', '==', bookingId), where('etablissement', '==', venueId)),
		querySnapshot => {
			const rooms = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })).filter(({ isDeleted }) => !isDeleted)
			dispatch(setRoomsOfBooking(rooms))
		},
		error => {
			console.log(error)
		}
	)
}

export const fetchRoomsOfGroup = (groupId, venueId) => dispatch => {
	return onSnapshot(
		query(collection(firestore, 'rooms'), where('groupId', '==', groupId), where('etablissement', '==', venueId)),
		querySnapshot => {
			const rooms = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })).filter(({ isDeleted }) => !isDeleted)
			dispatch(setRoomsOfBooking(rooms))
		},
		error => {
			console.log(error)
		}
	)
}

export const updateRooms = async rooms => {
	const batch = writeBatch(firestore)

	rooms.forEach(r => {
		batch.set(doc(firestore, 'rooms', r.id), r.data, { merge: true })
	})

	return batch.commit()
}

export const updateRoomsWithDispatch = data => async (_, getState) => {
	const rooms = getState().rooms.roomsOfBooking
	const batch = writeBatch(firestore)

	rooms.forEach(room => {
		batch.update(doc(firestore, 'rooms', room.id), data)
	})

	return batch.commit()
}

export const getNewRoomId = () => {
	return doc(collection(firestore, 'rooms')).id
}

export const getRooms = state => state.rooms.value
export const getRoomsStatus = state => state.rooms.status
export const getIsRoomsLoaded = state => state.rooms.isRoomsLoaded
export const getRoomsOfBooking = state => state.rooms.roomsOfBooking

export const { setRooms, setRoomStatus, setIsRoomsLoaded, setRoomsOfBooking } = roomsSlice.actions

export default roomsSlice.reducer
