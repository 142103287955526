import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../../backend'
import { setIsBookingsLoaded } from './bookingsReducer'
import { fetchClients, setIsClientsLoaded } from './clientsReducer'
import { fetchAccounts, setIsAccountsLoaded } from './accountsReducer'
import { fetchSpaces } from './spacesReducer'
import { fetchSources } from './sourcesReducer'
import { fetchStatus } from './statusReducer'
import { fetchUsers } from './usersReducer'
import { fetchProducts } from './productsReducer'
import { fetchCustomDates, setIsCustomDatesLoaded } from './customDatesReducer'
import { fetchSlots } from './slotsReducer'
import { fetchTemplates } from './templatesReducer'
import { fetchRooms, setIsRoomsLoaded } from './roomsReducer'
import { fetchFiles } from './filesReducer'
import { fetchGlobalVariables, fetchVariables } from './variablesReducer'
import { fetchLinks, setIsLinksLoaded } from './linksReducer'
import { fetchSteps } from './stepsReducer'
import { fetchCustomFields } from './customFieldsReducer'
import { collection, query, where, updateDoc, doc, onSnapshot } from 'firebase/firestore'
import i18n from '../../i18n'

export const saveVenue = createAsyncThunk('venues/saveVenue', async ({ id, data }) => {
	return updateDoc(doc(firestore, 'etablissements', id), data)
})

export const venuesSlice = createSlice({
	name: 'venues',
	initialState: {
		value: [],
		status: 'idle',
		error: null,
		selectedVenue: {},
	},
	reducers: {
		setVenues: (state, action) => {
			state.value = action.payload
		},
		setVenueStatus: (state, action) => {
			state.status = action.payload
		},
		setInitialSelectedVenue: (state, action) => {
			if (!state.selectedVenue.id) {
				state.selectedVenue = action.payload
			}
		},
		setSelectedVenue: (state, action) => {
			state.selectedVenue = action.payload
		},
	},
	extraReducers: {
		[saveVenue.pending]: (state, action) => {
			state.status = 'loading'
		},
		[saveVenue.fulfilled]: (state, action) => {
			state.status = 'succeeded'
		},
		[saveVenue.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

export const fetchVenues =
	(userId, handleSetListeners, willLoadAllVenues = false) =>
	(dispatch, getState) => {
		return onSnapshot(
			query(collection(firestore, 'etablissements'), where('users', 'array-contains', userId)),
			querySnapshot => {
				const venues = querySnapshot.docs
					.map(doc => ({ ...doc.data(), id: doc.id }))
					.sort((a, b) => {
						if (a.nom > b.nom) return 1
						if (a.nom < b.nom) return -1
						return 0
					})
				dispatch(setVenues(venues))

				const { selectedVenue } = getState().venues

				if (!selectedVenue?.id) {
					const initialSelectedVenue = venues[0]
					i18n.changeLanguage(initialSelectedVenue.defaultLang || 'fr')

					const venueIds = willLoadAllVenues ? venues.map(({ id }) => id) : [initialSelectedVenue.id]

					dispatch(setSelectedVenue(initialSelectedVenue))

					const arr = [
						dispatch(fetchClients(initialSelectedVenue.id)),
						dispatch(fetchAccounts(initialSelectedVenue.id)),
						dispatch(fetchLinks(initialSelectedVenue.id)),
						dispatch(fetchCustomDates(initialSelectedVenue.id)),
						dispatch(fetchRooms(initialSelectedVenue.id)),
						//
					]

					handleSetListeners(arr)
					dispatch(fetchGlobalVariables())
					dispatch(fetchTemplates(initialSelectedVenue.id))
					dispatch(fetchVariables(initialSelectedVenue.id))
					dispatch(fetchFiles(initialSelectedVenue.id))
					dispatch(fetchSlots(initialSelectedVenue.id))
					dispatch(fetchProducts(initialSelectedVenue.id))
					dispatch(fetchCustomFields({ venueIds, venueId: initialSelectedVenue.id }))
					dispatch(fetchSources({ venueIds, venueId: initialSelectedVenue.id }))
					dispatch(fetchStatus({ venueIds, venueId: initialSelectedVenue.id }))
					dispatch(fetchSpaces({ venueIds, venueId: initialSelectedVenue.id }))
					dispatch(fetchUsers({ venueIds, venueId: initialSelectedVenue.id }))
					dispatch(fetchSteps(initialSelectedVenue.id))
				} else {
					dispatch(setSelectedVenue(venues.find(({ id }) => id === selectedVenue.id)))
				}
			},
			error => {
				console.log(error)
			}
		)
	}

export const loadVenue = (venueId, handleSetListeners) => (dispatch, getState) => {
	const venue = getState().venues.value.find(({ id }) => id === venueId)
	// const venueIds = getState().venues.value.map(({ id }) => id)
	const venueIds = [venueId]

	i18n.changeLanguage(venue.defaultLang || 'fr')

	dispatch(setSelectedVenue(venue))
	dispatch(setIsBookingsLoaded(false))
	dispatch(setIsClientsLoaded(false))
	dispatch(setIsAccountsLoaded(false))
	dispatch(setIsCustomDatesLoaded(false))
	dispatch(setIsLinksLoaded(false))
	dispatch(setIsRoomsLoaded(false))
	//

	const arr = [
		dispatch(fetchClients(venueId)),
		dispatch(fetchAccounts(venueId)),
		dispatch(fetchLinks(venueId)),
		dispatch(fetchCustomDates(venueId)),
		dispatch(fetchRooms(venueId)),
		//
	]

	handleSetListeners(arr)

	dispatch(fetchGlobalVariables())
	dispatch(fetchTemplates(venueId))
	dispatch(fetchVariables(venueId))
	dispatch(fetchFiles(venueId))
	dispatch(fetchSlots(venueId))
	dispatch(fetchCustomDates(venueId))
	dispatch(fetchProducts(venueId))
	dispatch(fetchCustomFields({ venueIds, venueId }))
	dispatch(fetchSources({ venueIds, venueId }))
	dispatch(fetchStatus({ venueIds, venueId }))
	dispatch(fetchSpaces({ venueIds, venueId }))
	dispatch(fetchUsers({ venueIds, venueId }))
	dispatch(fetchSteps(venueId))
}

export const getVenues = state => state.venues.value
export const getVenueStatus = state => state.venues.status
export const getSelectedVenue = state => state.venues.selectedVenue

export const { setVenues, setVenueStatus, setInitialSelectedVenue, setSelectedVenue } = venuesSlice.actions

export default venuesSlice.reducer
