import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../../backend'
import { collection, doc, updateDoc, query, where, setDoc, getDocs } from 'firebase/firestore'

export const saveStatus = createAsyncThunk('status/saveStatus', async ({ id, data }) => {
	if (id) {
		return updateDoc(doc(firestore, 'status', id), data)
	} else {
		const statusId = doc(collection(firestore, 'status')).id
		return setDoc(doc(firestore, 'status', statusId), data, { merge: true })
	}
})

export const fetchStatus = createAsyncThunk('status/fetchStatus', async ({ venueIds = [], venueId }, { dispatch }) => {
	try {
		if (venueIds.length > 1) {
			const req = await Promise.all(Array.from({ length: Math.ceil(venueIds.length / 10) }).map((_, index) => getDocs(query(collection(firestore, 'status'), where('etablissement', 'in', venueIds.slice(index * 10, (index + 1) * 10))))))
			const status = req
				.reduce((res, querySnapshot) => [...res, ...querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))], [])
				.sort((a, b) => {
					if (parseFloat(a.order) > parseFloat(b.order)) return 1
					if (parseFloat(a.order) < parseFloat(b.order)) return -1
					return 0
				})

			dispatch(setStatus(status.filter(({ etablissement }) => etablissement === venueId)))
			dispatch(setAllStatus(status))
		} else {
			const querySnapshot = await getDocs(query(collection(firestore, 'status'), where('etablissement', '==', venueId)))
			const status = querySnapshot.docs
				.map(doc => ({ ...doc.data(), id: doc.id }))
				.sort((a, b) => {
					if (parseFloat(a.order) > parseFloat(b.order)) return 1
					if (parseFloat(a.order) < parseFloat(b.order)) return -1
					return 0
				})
			dispatch(setStatus(status))
		}
	} catch (error) {
		console.log(error)
	}
})

// export const fetchStatus = createAsyncThunk('status/fetchStatus', async (venueId, { dispatch }) => {
// 	try {
// 		const querySnapshot = await getDocs(query(collection(firestore, 'status'), where('etablissement', '==', venueId)))
// 		const status = querySnapshot.docs
// 			.map(doc => ({ ...doc.data(), id: doc.id }))
// 			.sort((a, b) => {
// 				if (parseFloat(a.order) > parseFloat(b.order)) return 1
// 				if (parseFloat(a.order) < parseFloat(b.order)) return -1
// 				return 0
// 			})
// 		dispatch(setStatus(status))
// 	} catch (error) {
// 		console.log(error)
// 	}
// })

export const statusSlice = createSlice({
	name: 'status',
	initialState: {
		value: [],
		status: 'idle',
		error: null,
		all_status: [],
	},
	reducers: {
		setStatus: (state, action) => {
			state.value = action.payload
		},
		setStatusStatus: (state, action) => {
			state.status = action.payload
		},
		setAllStatus: (state, action) => {
			state.all_status = action.payload
		},
	},
	extraReducers: {
		[saveStatus.pending]: (state, action) => {
			state.status = 'loading'
		},
		[saveStatus.fulfilled]: (state, action) => {
			state.status = 'succeeded'
		},
		[saveStatus.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

// export const fetchStatus = venueId => dispatch => {
// 	return onSnapshot(
// 		query(collection(firestore, 'status'), where('etablissement', '==', venueId)),
// 		querySnapshot => {
// 			const status = querySnapshot.docs
// 				.map(doc => ({ ...doc.data(), id: doc.id }))
// 				.sort((a, b) => {
// 					if (parseFloat(a.order) > parseFloat(b.order)) return 1
// 					if (parseFloat(a.order) < parseFloat(b.order)) return -1
// 					return 0
// 				})
// 			dispatch(setIsStatusLoaded(true))
// 			dispatch(setStatus(status))
// 		},
// 		error => {
// 			console.log(error)
// 		}
// 	)
// }

export const loadStatusFromVenue = async venueId => {
	const q = query(collection(firestore, 'status'), where('etablissement', '==', venueId))
	const querySnapshot = await getDocs(q)
	return querySnapshot.docs
		.map(doc => ({ ...doc.data(), id: doc.id }))
		.sort((a, b) => {
			if (a.order > b.order) return 1
			if (a.order < b.order) return -1
			return 0
		})
}

export const getStatus = state => state.status.value
export const getStatusStatus = state => state.status.status
export const getAllStatus = state => state.status.all_status

export const { setStatus, setStatusStatus, setAllStatus } = statusSlice.actions

export default statusSlice.reducer
