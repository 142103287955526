import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../../backend'
import { fetchVenues } from './venuesReducer'
import * as Sentry from '@sentry/react'
import { collection, query, where, updateDoc, doc, onSnapshot, getDocs } from 'firebase/firestore'

export const saveUser = createAsyncThunk('users/saveUser', async ({ id, data }) => {
	return updateDoc(doc(firestore, 'users', id), data)
})

export const fetchUsers = createAsyncThunk('users/fetchUsers', async ({ venueIds = [], venueId }, { dispatch }) => {
	try {
		if (venueIds.length > 1) {
			const req = await Promise.all(Array.from({ length: Math.ceil(venueIds.length / 10) }).map((_, index) => getDocs(query(collection(firestore, 'users'), where('venues', 'array-contains-any', venueIds.slice(index * 10, (index + 1) * 10))))))
			const users = req
				.reduce((res, querySnapshot) => [...res, ...querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))], [])
				.filter(({ isMaster, id }, index, self) => !isMaster && index === self.findIndex(t => t.id === id))
				.sort((a, b) => {
					if (a.prenom > b.prenom) return 1
					if (a.prenom < b.prenom) return -1
					return 0
				})

			dispatch(setUsers(users.filter(({ venues }) => venues.indexOf(venueId) > -1)))
			dispatch(setAllUsers(users))
		} else {
			const querySnapshot = await getDocs(query(collection(firestore, 'users'), where('venues', 'array-contains', venueId)))
			const users = querySnapshot.docs
				.map(doc => ({ ...doc.data(), id: doc.id }))
				.filter(({ isMaster }) => !isMaster)
				.sort((a, b) => {
					if (a.prenom > b.prenom) return 1
					if (a.prenom < b.prenom) return -1
					return 0
				})
			dispatch(setUsers(users))
		}
	} catch (error) {
		console.log(error)
	}
})

export const usersSlice = createSlice({
	name: 'users',
	initialState: {
		activeUser: {},
		value: [],
		all_users: [],
		status: 'idle',
		error: null,
	},
	reducers: {
		setActiveUser: (state, action) => {
			state.activeUser = action.payload
		},
		setUsers: (state, action) => {
			state.value = action.payload
		},
		setUserStatus: (state, action) => {
			state.status = action.payload
		},
		setAllUsers: (state, action) => {
			state.all_users = action.payload
		},
	},
	extraReducers: {
		[saveUser.pending]: (state, action) => {
			state.status = 'loading'
		},
		[saveUser.fulfilled]: (state, action) => {
			state.status = 'succeeded'
		},
		[saveUser.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

export const fetchActiveUser = (userId, handleSetListeners, handleSetSelectedVenueRelatedListeners) => dispatch => {
	return onSnapshot(
		doc(firestore, 'users', userId),
		doc => {
			if (doc.exists) {
				const user = doc.data()
				dispatch(setActiveUser({ ...user, id: userId }))
				handleSetListeners([dispatch(fetchVenues(userId, handleSetSelectedVenueRelatedListeners, !!user.hqTypesenseApiKey))])
				user?.email && Sentry.setUser({ email: user.email, id: userId })
			}
		},
		error => {
			console.log(error)
		}
	)
}

export const fetchUsersOfVenue = venueId => {
	return getDocs(query(collection(firestore, 'users'), where('venues', 'array-contains', venueId)))
}

export const getActiveUser = state => state.users.activeUser
export const getUsers = state => state.users.value
export const getActiveUserStatus = state => state.users.status
export const getAllUsers = state => state.users.all_users

export const { setActiveUser, setUsers, setUserStatus, setAllUsers } = usersSlice.actions

export default usersSlice.reducer
